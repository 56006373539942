<template>
  <main class="main">
    <section class="main__about">
      <h1>Страница не найдена</h1>
    </section>
  </main>
</template>

<script>

export default {
  name: 'PageNotFound',
  metaInfo () {
    return this.$seo(
      'common',
      'Страница не найдена',
      null,
      null,
      'Страница не найдена',
      null,
      null
    )
  }
}
</script>
